const SId = 'your_s_id_value';
const CId = 'your_c_id_value';

(function(SId, CId) {
    var BYOS = {
        isDebug: !! localStorage.getItem("by_debug"),
        console: {
            log: function(msg1, msg2) {
                if (!BYOS.isDebug) return;
                console.log("BEYABLE log :", msg1, msg2 || "");
            },
            error: function(msg1, msg2) {
                if (!BYOS.isDebug) return;
                console.error("BEYABLE error :", msg1, msg2 || "");
            },
        },
        debugger: function() {
            if (!BYOS.isDebug) return;
            debugger;
        },
        risky: function(func) {
            try {
                func();
            } catch (error) {
                BYOS.console.error(error);
            }
        },
    };
    BYOS.risky(function() {
        BYOS.console.log("Start", CId);
        var timer;
        var outer;
        var root;
        var target;
        var timeout;

        function checks() {
            BYOS.risky(function() {
                outer = window["by_r_" + SId];
                if (!outer) return;
                clearInterval(timer);
                obs = new MutationObserver(wait_outer_visibility);
                obs.observe(outer, {
                    attributes: true
                });
                wait_outer_visibility();
            });
        }
        function wait_outer_visibility(ml) {
            BYOS.risky(function() {
                if (outer.style.display == 'none') return;
                obs.disconnect();
                outer.style.zIndex = '';
                outer.style.position = '';
                init();
            });
        }
        function init() {
            alert('INIT');
            outer.classList.add("by_17555_wrapper");
            root = outer.querySelector(".by_17555");
            set_sumbit();
            var formOpener = root.querySelector(".by-js-popin-opener");
            console.log(formOpener);
            var formWrapper = root.querySelector(".by-js-formWrapper");
            var success_message = root.querySelector(".by-js-success-email");
            target.insertAdjacentElement("afterBegin", outer);
            formOpener.addEventListener("click", displaysForm);
            var new_session_id = get_session_id();
            if (new_session_id) {
                var old_ession_id = localStorage.getItem('by_17555_session_id');
                if (!old_ession_id || old_ession_id !== new_session_id) {
                    console.log("new");
                    localStorage.setItem('by_17555_session_id', new_session_id);
                    timeout = setTimeout(displaysForm, 5000);
                } else {
                    console.log("same");
                }
            }
            outer.addEventListener('BY_lib_form_submitted', function(e) {
                var hasError = e.detail.hasValidationError;
                if (!hasError) {
                    BY.by_SendInteractionForCampaign(0, "evtcpg:submit_formulaire", SId);
                    success_message.style.display = "";
                    setTimeout(function() {
                        BYOS.risky(function() {
                            success_message.style.display = "none";
                            formWrapper.classList.remove("is-visible");
                            var input = e.detail.form[0];
                            if (input) input.value = "";
                        });
                    }, 3000);
                }
            });
            var closeCta = root.querySelector(".by-js-close");
            closeCta.addEventListener("click", function() {
                formWrapper.classList.remove("is-visible");
            })
        }
        function get_session_id() {
            if (!beYableKey) return;
            var session = get_cookie(beYableKey);
            if (!session) return;
            return session.split('.')[0];
        }
        function get_cookie(name) {
            var cookies = document.cookie;
            if (cookies.length == 0) return;
            var start = cookies.indexOf(name + "=");
            if (start == -1) return;
            start = start + name.length + 1;
            var end = cookies.indexOf(";", start);
            if (end == -1) {
                end = cookies.length;
            }
            return unescape(cookies.substring(start, end));
        }
        function set_sumbit() {
            BYOS.risky(function() {
                var fake_btn = root.querySelector(".by-js-submit");
                var form_submit = root.querySelector(".by_lib_form_button_submit");
                if (fake_btn && form_submit) {
                    form_submit.insertAdjacentElement("beforebegin", fake_btn);
                    fake_btn.style.display = "";
                    form_submit.style.display = "none";
                    fake_btn.addEventListener("click", validate_form);
                }
            });
        };

        function validate_form() {
            BYOS.risky(function() {
                var form_submit = root.querySelector(".by_lib_form_button_submit");
                var errorEmailMessage = root.querySelector(".by-js-error-email");
                var emailOK = false;
                var is_email_valid = validate_email(document.querySelector("input[type='email']").value);
                if (is_email_valid) {
                    emailOK = true;
                    errorEmailMessage.style.display = "none";
                } else {
                    errorEmailMessage.style.display = "";
                    setTimeout(function() {
                        BYOS.risky(function() {
                            errorEmailMessage.style.display = "none";
                        });
                    }, 3000);
                }
                if (emailOK) {
                    form_submit.click();
                }
            });
        };

        function displaysForm() {
            BYOS.risky(function() {
                clearTimeout(timeout);
                var formWrapper = root.querySelector(".by-js-formWrapper");
                if (!formWrapper.classList.contains("is-visible")) {
                    formWrapper.style.display = "";
                    formWrapper.classList.add("is-visible");
                    BY.by_SendInteractionForCampaign(0, "evtcpg:view_campaign", SId);
                } else {
                    formWrapper.classList.remove("is-visible");
                }
            });
        };

        function validate_email(email) {
            var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        };
        window.addEventListener("BY_canInitCampaign", function(ev) {
            BYOS.risky(function() {
                if (!ev.detail) return;
                if (!ev.detail.CId) return;
                if (ev.detail.CId != CId) return;
                if (!ev.detail.target) return;
                BYOS.console.log("Condition OK", CId);
                target = ev.detail.target;
                timer = setInterval(checks, 50);
                checks();
            });
        });
    });
})(SId, CId);