const SId = 'your_s_id_value';
const CId = 'your_c_id_value';

(function(SId, CId) {
    var BYOS = {
        isDebug: !! localStorage.getItem("by_debug"),
        console: {
            log: function(msg1, msg2) {
                if (!BYOS.isDebug) return;
                console.log("BEYABLE log :", msg1, msg2 || "");
            },
            error: function(msg1, msg2) {
                if (!BYOS.isDebug) return;
                console.error("BEYABLE error :", msg1, msg2 || "");
            },
        },
        debugger: function() {
            if (!BYOS.isDebug) return;
            debugger;
        },
        risky: function(func) {
            try {
                func();
            } catch (error) {
                BYOS.console.error(error);
            }
        },
    };

    BYOS.risky(function() {
        BYOS.console.log("Start condition", CId);

        var timer;
        var target;
        var nb_checks = 100;

        function checks() {
            BYOS.risky(function() {
                nb_checks--;
                if (nb_checks <= 0) clearInterval(timer);

                target = document.querySelector(".header-main__menu-secondary");
                if (!target) return;

                validate();

                clearInterval(timer);
            });
        }

        function validate() {
            window["conditiononsite" + CId] = {};

            var event = new CustomEvent("BY_canInitCampaign", {
                detail: {
                    SId: SId,
                    CId: CId,
                    target: target,
                },
            });
            window.dispatchEvent(event);
        }

        timer = setInterval(checks, 50);
        checks();
    });
})(SId, CId);